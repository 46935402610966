<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card ref="form" elevation="2" class="pa-5 mt-5">
      <v-card-title>
        Create Tag
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>

        <v-text-field
          ref="name"
          v-model="name"
          :rules="rules.name"
          :error-messages="errorMessages"
          label="Name"
          required
        ></v-text-field>

        <v-textarea
          ref="description"
          v-model="description"
          :rules="rules.description"
          rows="1"
          auto-grow
          label="Description"
          id="description"
          counter
          :value="description"
        ></v-textarea>
      </v-card-text>

      <v-divider class="mt-12"></v-divider>
      <v-card-actions>
        <v-btn text>
          キャンセル
        </v-btn>
        <v-spacer></v-spacer>
        <v-slide-x-reverse-transition>
          <v-tooltip v-if="formHasErrors" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="my-0"
                v-bind="attrs"
                @click="resetForm"
                v-on="on"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh form</span>
          </v-tooltip>
        </v-slide-x-reverse-transition>
        <v-btn
          color="primary"
          text
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'タグ管理',
          disabled: false,
          to: { name: 'Tags' },
          exact: true
        },
        {
          text: 'Create',
          disabled: false,
          to: { name: '' },
          exact: true
        }
      ],
      name: null,
      description: null,
      errorMessages: '',
      formHasErrors: false,
      alert: false,
      status: null,
      statusMessage: null,
      rules: {
        name: [v => !!v || 'タグ名は必須です。'],
        description: [v => !!v || '説明が必要']
      },
      loading: false
    }
  },

  computed: {
    form() {
      return {
        name: this.name,
        description: this.description
      }
    },
    formData() {
      var formData = new FormData()
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('content', this.content)
      return formData
    }
  },

  methods: {
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },

    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) {
        this.createTag()
      }
    },

    createTag() {
      this.loading = true
      api
        .add('tags', this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          this.resetForm()
          this.loading = false
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 5000)
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  }
}
</script>
